<template>
  <div class="wrapper">
    <div class="back-btn">
      <div>
        <admin-title :title="$route.meta.name"></admin-title>
      </div>
      <div>
        <el-button type="info" size="small" icon="el-icon-back" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClickTabs">
      <el-tab-pane label="基本信息" :name="1"> </el-tab-pane>
      <el-tab-pane label="规格/库存" :name="2"> </el-tab-pane>
      <el-tab-pane label="商品详情" :name="3"></el-tab-pane>
    </el-tabs>
    <el-form ref="form" :model="formData" label-width="100px">
      <!-- 基本信息 -->
      <div v-show="activeName == 1" class="baseInfo-1">
        <el-descriptions>
          <el-descriptions-item label="商品标题">{{ formData.goodsTitle }}</el-descriptions-item>
          <el-descriptions-item label="商品副标题">{{ formData.subTitle }}</el-descriptions-item>
          <el-descriptions-item label="商品标签">
            <el-tag style="margin-right:8px;" size="small" v-for="tag in formData.goodsTagList" :key="tag">{{ tag }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="详情标题">
            <span style="padding-right:20px;">{{ formData.detailTitle }}</span>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="详情标题">
           
          </el-descriptions-item> -->
          <el-descriptions-item label="商品分类">{{ categoryName }}</el-descriptions-item>
          <el-descriptions-item label="套餐类型">{{ formData.goodsComboName }}</el-descriptions-item>
          <el-descriptions-item label="账单期数">{{ formData.billPeriodNum }} 期</el-descriptions-item>
          <el-descriptions-item label="首付期数">
            <span style="padding-right:20px;">
              <el-tag style="margin-right:8px;" size="small" v-for="tag in formData.prepayPeriodList" :key="tag">第{{ tag }}期</el-tag>
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="付款周期"> {{ formData.periodInterval }}{{ formData.periodUnitName }} </el-descriptions-item>
          <el-descriptions-item label="首页推荐">
            <el-tag size="small" :type="`${formData.isRecommend == 1 ? 'primary' : 'danger'}`">{{
              `${formData.isRecommend == 1 ? '是' : '否'}`
            }}</el-tag>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <!-- 规格/库存 -->
      <div v-show="activeName == 2" class="baseInfo-2">
        <el-form-item label="价格系数：" prop="priceRate" :rules="rules">
          {{ formData.priceRate }}
        </el-form-item>
        <Sku
          ref="sku"
          @addStock="addStock"
          :priceRate="formData.priceRate"
          :skuAttrGroupList="formData.skuAttrGroupList"
          :skuFormList="formData.skuList"
        ></Sku>
        <el-empty v-show="!formData.skuList.length > 0" description="暂无规格"></el-empty>
      </div>
      <!-- 商品详情 -->
      <div v-show="activeName == 3" class="baseInfo-3">
        <el-descriptions>
          <el-descriptions-item label="买断金">{{ formData.buyoutPrice }}</el-descriptions-item>
          <el-descriptions-item label="溢价金">{{ formData.overflowPrice }}</el-descriptions-item>
          <el-descriptions-item label="保险金">{{ formData.insurancePrice }}</el-descriptions-item>
          <el-descriptions-item label="基础销量">{{ formData.baseSaleNum }}</el-descriptions-item>
          <el-descriptions-item label="封面图">
            <el-image style="width:70px;height:70px;" :src="formData.coverImageUrl" :preview-src-list="[formData.coverImageUrl]" alt="" />
          </el-descriptions-item>
          <el-descriptions-item label="主图">
            <template v-for="item in formData.masterImageUrlList">
              <el-image style="width:70px;height:70px;margin-right:5px;" :key="item" :src="item" :preview-src-list="[item]" alt="" />
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="详情图">
            <el-image
              style="width:70px;height:70px;margin-right:5px;"
              v-for="item in formData.detailImageUrlList"
              :key="item"
              :src="item"
              :preview-src-list="[item]"
              alt=""
            />
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-form>
    <!-- 弹窗 -->
    <el-dialog title="增加库存" :visible.sync="dialogVisible" width="700px">
      <el-form ref="stockForm" :model="stockFormData" label-width="80px">
        <el-form-item label="增加库存">
          <el-input v-model.number="stockFormData.stockNum" size="small" style="width:300px;"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button type="primary" @click="saveStock" size="small">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { editGoodsAPI, addGoodsAPI, getGoodsDetailAPI, addGoodsStockAPI } from './api'
import SelectGoodsCategory from '@/views/components/select-goods-category.vue'
import SelectDict from '@/views/components/select-dict.vue'
import FileUpload from '@/components/file-upload/index.vue'
import Sku from './sku-form.vue'
export default {
  name: 'AddOrEdit',
  components: { Sku },
  data() {
    return {
      activeName: 1,
      formData: {
        categoryId: 1,
        goodsTitle: 'iPhone 14 Pro MAX',
        subTitle: 'iPhone 14 Pro MAX 12期',
        detailTitle:
          '【现货】iPhone 14 Pro max 首页 5 折 现货发售，审批通过后，1-5工作日发货 全新国货！未拆封！未激活！无锁！非监管机！90%以上免押金！顺丰包邮！',
        goodsTagList: ['包邮'],
        goodsCombo: 'buyoutAnytime',
        priceRate: 135,
        buyoutPrice: '199.00',
        overflowPrice: '99.00',
        insurancePrice: '109.00',
        periodInterval: 1,
        periodUnit: 'week',
        billPeriodNum: 12,
        prepayPeriodList: [1, 2],
        coverImage: '',
        coverImageUrl: '',
        masterImageList: [],
        masterImageUrlList: [],
        detailImageList: [],
        detailImageUrlList: [],
        baseSaleNum: 100,
        isRecommend: 1,
        skuList: []
        // rentPrice: ''
      },
      // goodsTagList: ['标签一', '标签二', '标签三'],
      inputVisible: false,
      inputValue: '',
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      stockFormData: {
        id: '',
        stockNum: 0
      },
      dialogVisible: false
    }
  },

  mounted() {
    if (this.$route.params.id) this.getGoodsDetail()
  },
  computed: {
    validateTab() {
      let active = ''
      const {
        goodsTitle,
        subTitle,
        goodsTagList,
        detailTitle,
        categoryId,
        goodsCombo,
        billPeriodNum,
        prepayPeriodList,
        periodInterval,
        priceRate,
        buyoutPrice,
        overflowPrice,
        insurancePrice,
        baseSaleNum,
        coverImage,
        masterImageList,
        detailImageList
      } = this.formData
      const tab1 =
        goodsTitle &&
        subTitle &&
        goodsTagList.length &&
        detailTitle &&
        categoryId &&
        goodsCombo &&
        billPeriodNum &&
        prepayPeriodList.length &&
        periodInterval
      const tab3 = buyoutPrice && overflowPrice && insurancePrice && baseSaleNum && coverImage && masterImageList.length && detailImageList.length
      const tab2 = priceRate && this.formData.skuList.length > 0

      if (!tab1) {
        return (active = '1')
      } else if (!tab2) {
        return (active = '2')
      } else if (!tab3) {
        return (active = '3')
      }
      return active
    },
    categoryName() {
      let name = ''
      const { category } = this.formData
      if (category) {
        name = category.categoryName
      }

      return name
    }
  },
  methods: {
    // 保存商品
    save() {
      this.$refs.form.validate(valid => {
        this.formData.skuList = this.$refs.sku.skuList
        this.formData.rentPrice = this.$refs.sku.rentPrice
        if (valid) {
          if (this.formData.id) {
            // 编辑
            editGoodsAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            // 新增
            addGoodsAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        } else {
          this.activeName = this.validateTab
          if (this.validateTab == 2) this.$message.error('商品规格必填！')
        }
      })
    },
    // 新增一个规格
    addSku() {
      let data = {
        skuTitle: '',
        officialPrice: '',
        stockNum: 0,
        coverImage: '',
        skuAttrList: []
      }
      this.formData.skuList.push(data)
    },
    // 删除一个规格
    deleteSku(skuIndex) {
      this.formData.skuList.splice(skuIndex, 1)
    },
    // 新增一个属性
    addSkuAttr(skuIndex) {
      let attr = {
        group: '',
        value: ''
      }
      this.formData.skuList[skuIndex].skuAttrList.push(attr)
    },
    // 删除一个属性
    deleteSkuAttr(skuIndex, attrIndex) {
      this.formData.skuList[skuIndex].skuAttrList.splice(attrIndex, 1)
    },
    // 商品详情
    async getGoodsDetail() {
      this.formData = await getGoodsDetailAPI(this.$route.params.id)
    },
    handleClickTabs() {},
    handleClose(tag) {
      this.formData.goodsTagList.splice(this.formData.goodsTagList.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.formData.goodsTagList.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    next() {
      this.activeName++
    },
    prev() {
      this.activeName = this.activeName - 1
    },
    addStock(id) {
      this.dialogVisible = true
      this.stockFormData.id = id
    },
    cancel() {
      this.dialogVisible = false
      this.stockFormData = { id: '', stockNum: 0 }
    },
    saveStock() {
      addGoodsStockAPI(this.stockFormData).then(() => {
        this.dialogVisible = false
        this.$message.success('增加成功')
        this.getGoodsDetail()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form /deep/ {
    .baseInfo-1,
    .baseInfo-3 {
      .el-input,
      .el-textarea {
        width: 500px;
      }
    }
    .el-form-item {
      .goods-tags {
        width: 500px;
        .el-tag + .el-tag {
          margin-left: 10px;
        }
        .button-new-tag {
          margin-left: 10px;
          height: 32px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
        }
        .input-new-tag {
          width: 90px;
          margin-left: 10px;
          vertical-align: bottom;
        }
      }
    }
    .period {
      .el-select,
      .el-input {
        width: 240px !important;
      }
    }
    .goods-category {
      margin-bottom: 0;
      .tips-btn {
        span {
          cursor: pointer;
          margin-right: 10px;
          color: #1890ff;
          font-size: 12px;
        }
      }
    }
    .goods-sku {
      min-width: 1000px;
      // max-width: 1300px;
      .sku-title,
      .sku-content {
        width: 100%;
        display: flex;
        padding: 10px;
        justify-content: space-between;
        & > span {
          flex: 2;
          &:nth-child(1) {
            flex: 1;
          }
          &:nth-child(2) {
            flex: 3;
          }
          &:nth-child(5) {
            flex: 1.5;
          }
          &:nth-child(6) {
            flex: 3;
          }
          &:nth-child(7) {
            flex: 1;
          }
        }
        .el-form-item {
          margin-bottom: 0;
        }
      }
      .sku-title {
        background-color: #f4f5f9;
        & > span {
          padding-right: 20px;
          // &:last-child {
          //   display: flex;
          //   justify-content: space-between;
          // }
        }
      }
      .sku-content {
        border-bottom: 1px solid #e4e5e9;
        background-color: #fbfbfb;
        & > span {
          margin: auto;
          padding-right: 20px;
        }
        .sku-attr {
          display: flex;
          flex-direction: column;
          .attr-title,
          .attr-value {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            & > span {
              flex: 1;
              display: flex;
              align-items: center;
              &:nth-child(-n + 2) {
                margin-right: 10px;
                flex: 2;
              }
            }
          }
        }
      }
    }
  }
  .back-btn {
    display: flex;
    justify-content: space-between;
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .el-descriptions-item {
    padding-bottom: 20px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
  .goods-sku,
  .baseInfo-2 {
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }
    .avatar {
      width: 60px;
      height: 60px;
      display: block;
    }
  }
}
</style>
